
.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    [class*='owl-'] {
      color: $nav-color;
      font-size: $nav-font-size;
      margin: $nav-margin;
      padding: $nav-padding;
      background: $nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background: $nav-background-hover;
        color: $nav-color-hover;
        text-decoration: none;
      }
    }

    .disabled {
      opacity: $nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $dot-width;
        height: $dot-height;
        margin: $dot-margin;
        background: $dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: $dot-rounded;
      }

      &.active,
      &:hover {
        span {
          background: $dot-background-active;
        }
      }
    }
  }
}


/* LEFT AND RIGHT ARROWS ON COMPARE VANS WORKING ON THIS */

.carousel-wrap {
  width: 100%;
  margin: auto;
  position: relative;
}

.owl-vantypes .owl-nav {
  overflow: hidden;
  height: 0px;
}

.owl-vantypes .item {
  text-align: center;
}

.owl-vantypes .nav-btn {
  position: absolute;
  top: 10% !important;
}

.owl-vantypes .owl-prev.disabled,
.owl-vantypes .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-vantypes .prev-slide {
  left: -26px;
}

.owl-vantypes .next-slide {
  right: -26px;
}

.owl-vantypes .prev-slide:hover {
  background-position: 0px -53px;
}

.owl-vantypes .next-slide:hover {
  background-position: -24px -53px;
}
/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base:			#869791 !default;
$color-white:			#FFF !default;
$color-gray:			#D6D6D6 !default;

$brand-quaternary: #DBA127;

//nav

$nav-color: 			$color-white !default;
$nav-color-hover:		$color-white !default;
$nav-font-size: 		20px !default;
$nav-rounded: 			3px !default;
$nav-margin: 			5px !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		$brand-quaternary; // Declared at top
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:				20px !default;
$dot-height:			20px !default;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		$color-gray !default;
$dot-background-active:	$brand-quaternary; // Declared at top





@import 'theme';
